export const customFrontendId = {
  cinepolisTicket: 'CINEPOLIS_TICKET',
  cinepolisFood: 'CINEPOLIS_FOOD',
  cinepolisMexicoTicket: 'CINEPOLIS_MEXICO_TICKET',
  kfcIntroScreen: 'KFC_INTROSCREEN',
  wellness: 'WELLNESS',
  kfcTemplate: 'kfc',
  pizzahutDevyani: 'pizzahutDevyani',
  pdf: 'PDF',
  decathlon: 'DECATHLON',
  rrGold: 'RR_GOLD_PALACE',
  prasads: 'PRASAD_TICKET',
  mcd: 'MCD',
  ecomPlatform: 'ECOM_PLATFORM',
  // new
  png: 'png', // basically default template. no need for aditional condition
  dinapala: 'DINAPALA',
  bKIndia: 'BKIND',
  cinepolisIndia: 'CINEPOLISIND',
  nykaaIndia: 'NYKAIND',
  decathlonIndia: 'DECATHIND',
  decathlonSriLanka: 'DECATHLANKA',
  starquik: 'STARQUIK',
  marcaDisati: 'MARCADISATI',
  bestSeller: 'BESTSELLER',
  veltosa: 'VELTOSA',
  centro: 'CENTRO',
  shree: 'SHR',
  centroGrande: 'CENTRO_GRANDE',
  esbeda: 'ESBEDA',
  superK: 'SUPERK_BILL',
  nykaaFeedbackTest: 'NYKAA_FEEDBACK_TEST',
  cinemaBill: 'CINEMA_BILL',
  relaxo: 'RELAXO_FOOTWEAR',
  nuttysDen: 'NUTTYS_DEN',
  gadchiroli: 'GADCHIROLI_PROP_TAX',
  CCD: 'CCD_BILL',
  beautynBeyond: 'BEAUTY_BEYOND',
  rawMango: 'RAW_MANGO',
  sprvl: 'SPRVL_ECOM',
  twc: 'THIRD_WAVE_COFFEE',
  hdfc: 'HDFC_CHRG_SLIP',
  utsa: 'UTSA',
  decathIndPlay: 'DECATHIND_PLAY',
  v2EcomApi: 'V2_ECOM_API',
  kaya: 'KAYA_CLINIC',
  cityOfPatna: 'CITY_OF_PATNA',
  bewakoof: 'BEWAKOOF_BILL',
  bewakoofStage: 'TMRW_BILL',
  goColors: 'GO_COLORS_BILL',
  manyavar: 'MANYAVAR_BILL',
  wonderSoft: 'WONDERSOFT_BILL',
};
